import 'flag-icons/css/flag-icons.min.css';
import JoinComunicatorSection from '@/components/JoinComunicatorSection';
import SearchBar from '@/components/SearchBar';
import ShowButton from '@/components/ShowButton';
import WorldMap from '@/components/WorldMap';
import { getCookie } from '@/utils/helpFunctions';
import Head from 'next/head';
import router from 'next/router';
import { useEffect, useState } from 'react';
import Link from 'next/link';

export const countries = [
  { code: 'af', value: 'afghanistan', name: 'Afghanistan', nativeName: 'افغانستان' },
  { code: 'al', value: 'albania', name: 'Albania', nativeName: 'Shqipëria' },
  { code: 'dz', value: 'algeria', name: 'Algeria', nativeName: 'الجزائر' },
  { code: 'ao', value: 'angola', name: 'Angola', nativeName: 'Angola' },
  { code: 'aq', value: 'antarctica', name: 'Antarctica', nativeName: 'Antarctica' },
  { code: 'ar', value: 'argentina', name: 'Argentina', nativeName: 'Argentina' },
  { code: 'am', value: 'armenia', name: 'Armenia', nativeName: 'Հայաստան' },
  { code: 'au', value: 'australia', name: 'Australia', nativeName: 'Australia' },
  { code: 'at', value: 'austria', name: 'Austria', nativeName: 'Österreich' },
  { code: 'az', value: 'azerbaijan', name: 'Azerbaijan', nativeName: 'Azərbaycan' },
  { code: 'bs', value: 'bahamas', name: 'Bahamas', nativeName: 'The Bahamas' },
  { code: 'bd', value: 'bangladesh', name: 'Bangladesh', nativeName: 'বাংলাদেশ' },
  { code: 'by', value: 'belarus', name: 'Belarus', nativeName: 'Беларусь' },
  { code: 'be', value: 'belgium', name: 'Belgium', nativeName: 'België / Belgique / Belgien' },
  { code: 'bz', value: 'belize', name: 'Belize', nativeName: 'Belize' },
  { code: 'bj', value: 'benin', name: 'Benin', nativeName: 'Bénin' },
  { code: 'bt', value: 'bhutan', name: 'Bhutan', nativeName: 'འབྲུག' },
  { code: 'bo', value: 'bolivia', name: 'Bolivia', nativeName: 'Bolivia' },
  { code: 'ba', value: 'bosniaandherzegovina', name: 'Bosnia and Herz.', nativeName: 'Bosna i Hercegovina' },
  { code: 'bw', value: 'botswana', name: 'Botswana', nativeName: 'Botswana' },
  { code: 'br', value: 'brazil', name: 'Brazil', nativeName: 'Brasil' },
  { code: 'bn', value: 'brunei', name: 'Brunei', nativeName: 'Brunei' },
  { code: 'bg', value: 'bulgaria', name: 'Bulgaria', nativeName: 'България' },
  { code: 'bf', value: 'burkinafaso', name: 'Burkina Faso', nativeName: 'Burkina Faso' },
  { code: 'bi', value: 'burundi', name: 'Burundi', nativeName: 'Burundi' },
  { code: 'kh', value: 'cambodia', name: 'Cambodia', nativeName: 'កម្ពុជា' },
  { code: 'cm', value: 'cameroon', name: 'Cameroon', nativeName: 'Cameroun' },
  { code: 'ca', value: 'canada', name: 'Canada', nativeName: 'Canada' },
  { code: 'cf', value: 'centralafricanrepublic', name: 'Central African Rep.', nativeName: 'République centrafricaine' },
  { code: 'td', value: 'chad', name: 'Chad', nativeName: 'Tchad' },
  { code: 'cl', value: 'chile', name: 'Chile', nativeName: 'Chile' },
  { code: 'cn', value: 'china', name: 'China', nativeName: '中国' },
  { code: 'co', value: 'colombia', name: 'Colombia', nativeName: 'Colombia' },
  { code: 'cg', value: 'congobrazzaville', name: 'Congo', nativeName: 'République du Congo' },
  { code: 'cr', value: 'costarica', name: 'Costa Rica', nativeName: 'Costa Rica' },
  { code: 'ci', value: 'cotedivoire', name: "Côte d'Ivoire", nativeName: "Côte d'Ivoire" },
  { code: 'hr', value: 'croatia', name: 'Croatia', nativeName: 'Hrvatska' },
  { code: 'cu', value: 'cuba', name: 'Cuba', nativeName: 'Cuba' },
  { code: 'cy', value: 'cyprus', name: 'Cyprus', nativeName: 'Κύπρος' },
  { code: 'cz', value: 'czechia', name: 'Czechia', nativeName: 'Česko' },
  { code: 'cd', value: 'congokinshasa', name: 'Dem. Rep. Congo', nativeName: 'République démocratique du Congo' },
  { code: 'dk', value: 'denmark', name: 'Denmark', nativeName: 'Danmark' },
  { code: 'dj', value: 'djibouti', name: 'Djibouti', nativeName: 'Djibouti' },
  // { code: 'dm', value: 'dominica', name: 'Dominica', nativeName: 'Dominica' },
  { code: 'do', value: 'dominicanrepublic', name: 'Dominican Rep.', nativeName: 'República Dominicana' },
  { code: 'ec', value: 'ecuador', name: 'Ecuador', nativeName: 'Ecuador' },
  { code: 'eg', value: 'egypt', name: 'Egypt', nativeName: 'مصر' },
  { code: 'sv', value: 'elsalvador', name: 'El Salvador', nativeName: 'El Salvador' },
  { code: 'gq', value: 'equatorialguinea', name: 'Eq. Guinea', nativeName: 'Guinea Ecuatorial' },
  { code: 'er', value: 'eritrea', name: 'Eritrea', nativeName: 'ኤርትራ' },
  { code: 'ee', value: 'estonia', name: 'Estonia', nativeName: 'Eesti' },
  { code: 'sz', value: 'eswatini', name: 'Eswatini', nativeName: 'Eswatini' },
  { code: 'et', value: 'ethiopia', name: 'Ethiopia', nativeName: 'ኢትዮጵያ' },
  { code: 'fk', value: 'falklandislands', name: 'Falkland Is.', nativeName: 'Falkland Islands' },
  { code: 'fj', value: 'fiji', name: 'Fiji', nativeName: 'Viti' },
  { code: 'fi', value: 'finland', name: 'Finland', nativeName: 'Suomi' },
  //Fr. S. Antarctic Lands
  { code: 'fr', value: 'france', name: 'France', nativeName: 'France' },
  // { code: 'gf', value: 'frenchguiana', name: 'French Guiana', nativeName: 'Guyane' },
  // { code: 'pf', value: 'frenchpolynesia', name: 'French Polynesia', nativeName: 'Polynésie française' },
  // { code: 'tf', value: 'frenchsouthernterritories', name: 'French Southern Territories', nativeName: 'Terres australes françaises' },
  { code: 'ga', value: 'gabon', name: 'Gabon', nativeName: 'Gabon' },
  { code: 'gm', value: 'gambia', name: 'Gambia', nativeName: 'The Gambia' },
  { code: 'ge', value: 'georgia', name: 'Georgia', nativeName: 'საქართველო' },
  { code: 'de', value: 'germany', name: 'Germany', nativeName: 'Deutschland' },
  { code: 'gh', value: 'ghana', name: 'Ghana', nativeName: 'Ghana' },
  { code: 'gr', value: 'greece', name: 'Greece', nativeName: 'Ελλάδα' },
  { code: 'gl', value: 'greenland', name: 'Greenland', nativeName: 'Kalaallit Nunaat' },
  { code: 'gt', value: 'guatemala', name: 'Guatemala', nativeName: 'Guatemala' },
  { code: 'gn', value: 'guinea', name: 'Guinea', nativeName: 'Guinée' },
  { code: 'gw', value: 'guineabissau', name: 'Guinea-Bissau', nativeName: 'Guiné-Bissau' },
  { code: 'gy', value: 'guyana', name: 'Guyana', nativeName: 'Guyana' },
  { code: 'ht', value: 'haiti', name: 'Haiti', nativeName: 'Haïti' },
  { code: 'hn', value: 'honduras', name: 'Honduras', nativeName: 'Honduras' },
  { code: 'hu', value: 'hungary', name: 'Hungary', nativeName: 'Magyarország' },
  { code: 'is', value: 'iceland', name: 'Iceland', nativeName: 'Ísland' },
  { code: 'in', value: 'india', name: 'India', nativeName: 'भारत' },
  { code: 'id', value: 'indonesia', name: 'Indonesia', nativeName: 'Indonesia' },
  { code: 'ir', value: 'iran', name: 'Iran', nativeName: 'ایران' },
  { code: 'iq', value: 'iraq', name: 'Iraq', nativeName: 'العراق' },
  { code: 'ie', value: 'ireland', name: 'Ireland', nativeName: 'Éire' },
  { code: 'il', value: 'israel', name: 'Israel', nativeName: 'יִשְׂרָאֵל' },
  { code: 'it', value: 'italy', name: 'Italy', nativeName: 'Italia' },
  { code: 'jm', value: 'jamaica', name: 'Jamaica', nativeName: 'Jamaica' },
  { code: 'jp', value: 'japan', name: 'Japan', nativeName: '日本' },
  { code: 'jo', value: 'jordan', name: 'Jordan', nativeName: 'الأردن' },
  { code: 'kz', value: 'kazakhstan', name: 'Kazakhstan', nativeName: 'Қазақстан' },
  { code: 'ke', value: 'kenya', name: 'Kenya', nativeName: 'Kenya' },
  { code: 'xk', value: 'kosovo', name: 'Kosovo', nativeName: 'Kosovo' },
  { code: 'kw', value: 'kuwait', name: 'Kuwait', nativeName: 'الكويت' },
  { code: 'kg', value: 'kyrgyzstan', name: 'Kyrgyzstan', nativeName: 'Кыргызстан' },
  // { code: 'la', value: 'laos', name: 'Laos', nativeName: 'ລາວ' },
  // { code: 'lv', value: 'latvia', name: 'Latvia', nativeName: 'Latvija' },
  // { code: 'lb', value: 'lebanon', name: 'Lebanon', nativeName: 'لبنان' },
  // { code: 'ls', value: 'lesotho', name: 'Lesotho', nativeName: 'Lesotho' },
  // { code: 'lr', value: 'liberia', name: 'Liberia', nativeName: 'Liberia' },
  // { code: 'ly', value: 'libya', name: 'Libya', nativeName: 'ليبيا' },
  // { code: 'lt', value: 'lithuania', name: 'Lithuania', nativeName: 'Lietuva' },
  // { code: 'lu', value: 'luxembourg', name: 'Luxembourg', nativeName: 'Luxembourg' },
  // { code: 'mk', value: 'macedonia', name: 'Macedonia', nativeName: 'Македонија' },
  // { code: 'mg', value: 'madagascar', name: 'Madagascar', nativeName: 'Madagascar' },
  // { code: 'mw', value: 'malawi', name: 'Malawi', nativeName: 'Malawi' },
  // { code: 'my', value: 'malaysia', name: 'Malaysia', nativeName: 'Malaysia' },
  // { code: 'kp', value: 'northkorea', name: 'North Korea', nativeName: '조선' },
  // { code: 'kr', value: 'southkorea', name: 'South Korea', nativeName: '대한민국' },
  // { code: 'kw', value: 'kuwait', name: 'Kuwait', nativeName: 'الكويت' },
  // { code: 'kg', value: 'kyrgyzstan', name: 'Kyrgyzstan', nativeName: 'Кыргызстан' },
  { code: 'la', value: 'laos', name: 'Laos', nativeName: 'ລາວ' },
  { code: 'lv', value: 'latvia', name: 'Latvia', nativeName: 'Latvija' },
  { code: 'lb', value: 'lebanon', name: 'Lebanon', nativeName: 'لبنان' },
  { code: 'ls', value: 'lesotho', name: 'Lesotho', nativeName: 'Lesotho' },
  { code: 'lr', value: 'liberia', name: 'Liberia', nativeName: 'Liberia' },
  { code: 'ly', value: 'libya', name: 'Libya', nativeName: 'ليبيا' },
  { code: 'lt', value: 'lithuania', name: 'Lithuania', nativeName: 'Lietuva' },
  { code: 'lu', value: 'luxembourg', name: 'Luxembourg', nativeName: 'Luxembourg / Lëtzebuerg' },
  { code: 'mk', value: 'macedonia', name: 'Macedonia', nativeName: 'Македонија' },
  { code: 'mg', value: 'madagascar', name: 'Madagascar', nativeName: 'Madagasikara' },
  { code: 'mw', value: 'malawi', name: 'Malawi', nativeName: 'Malawi' },
  { code: 'my', value: 'malaysia', name: 'Malaysia', nativeName: 'Malaysia' },
  { code: 'ml', value: 'mali', name: 'Mali', nativeName: 'Mali' },
  { code: 'mr', value: 'mauritania', name: 'Mauritania', nativeName: 'موريتانيا' },
  { code: 'mx', value: 'mexico', name: 'Mexico', nativeName: 'México' },
  { code: 'md', value: 'moldova', name: 'Moldova', nativeName: 'Republica Moldova' },
  { code: 'mn', value: 'mongolia', name: 'Mongolia', nativeName: 'Монгол улс' },
  { code: 'me', value: 'montenegro', name: 'Montenegro', nativeName: 'Crna Gora' },
  { code: 'ma', value: 'morocco', name: 'Morocco', nativeName: 'المغرب' },
  { code: 'mz', value: 'mozambique', name: 'Mozambique', nativeName: 'Moçambique' },
  { code: 'mm', value: 'myanmar', name: 'Myanmar', nativeName: 'မြန်မာ' },
  //N. Cyprus
  { code: 'na', value: 'namibia', name: 'Namibia', nativeName: 'Namibia' },
  { code: 'np', value: 'nepal', name: 'Nepal', nativeName: 'नेपाल' },
  { code: 'nl', value: 'netherlands', name: 'Netherlands', nativeName: 'Nederland' },
  { code: 'nc', value: 'newcaledonia', name: 'New Caledonia', nativeName: 'Nouvelle-Calédonie' },
  { code: 'nz', value: 'newzealand', name: 'New Zealand', nativeName: 'Aotearoa' },
  { code: 'ni', value: 'nicaragua', name: 'Nicaragua', nativeName: 'Nicaragua' },
  { code: 'ne', value: 'niger', name: 'Niger', nativeName: 'Niger' },
  { code: 'ng', value: 'nigeria', name: 'Nigeria', nativeName: 'Nigeria' },
  //North Korea
  { code: 'no', value: 'norway', name: 'Norway', nativeName: 'Norge' },
  { code: 'om', value: 'oman', name: 'Oman', nativeName: 'عُمان' },
  { code: 'pk', value: 'pakistan', name: 'Pakistan', nativeName: 'پاکستان' },
  { code: 'ps', value: 'palestine', name: 'Palestine', nativeName: 'فلسطين' },
  { code: 'pa', value: 'panama', name: 'Panama', nativeName: 'Panamá' },
  { code: 'pg', value: 'papuanewguinea', name: 'Papua New Guinea', nativeName: 'Papua New Guinea' },
  { code: 'py', value: 'paraguay', name: 'Paraguay', nativeName: 'Paraguay' },
  { code: 'pe', value: 'peru', name: 'Peru', nativeName: 'Perú' },
  { code: 'ph', value: 'philippines', name: 'Philippines', nativeName: 'Pilipinas' },
  { code: 'pl', value: 'poland', name: 'Poland', nativeName: 'Polska' },
  { code: 'pt', value: 'portugal', name: 'Portugal', nativeName: 'Portugal' },
  { code: 'pr', value: 'puertorico', name: 'Puerto Rico', nativeName: 'Puerto Rico' },
  { code: 'qa', value: 'qatar', name: 'Qatar', nativeName: 'قطر' },
  { code: 'ro', value: 'romania', name: 'Romania', nativeName: 'România' },
  { code: 'ru', value: 'russia', name: 'Russia', nativeName: 'Россия' },
  { code: 'rw', value: 'rwanda', name: 'Rwanda', nativeName: 'Rwanda' },
  { code: 'ss', value: 'southsudan', name: 'S. Sudan', nativeName: 'South Sudan' },
  { code: 'sa', value: 'saudiarabia', name: 'Saudi Arabia', nativeName: 'المملكة العربية السعودية' },
  { code: 'sn', value: 'senegal', name: 'Senegal', nativeName: 'Sénégal' },
  { code: 'rs', value: 'serbia', name: 'Serbia', nativeName: 'Србија' },
  { code: 'sl', value: 'sierraleone', name: 'Sierra Leone', nativeName: 'Sierra Leone' },
  { code: 'sk', value: 'slovakia', name: 'Slovakia', nativeName: 'Slovensko' },
  { code: 'si', value: 'slovenia', name: 'Slovenia', nativeName: 'Slovenija' },
  { code: 'sb', value: 'solomonislands', name: 'Solomon Is.', nativeName: 'Solomon Islands' },
  { code: 'so', value: 'somalia', name: 'Somalia', nativeName: 'Soomaaliya' },
  //Somaliland
  { code: 'za', value: 'southafrica', name: 'South Africa', nativeName: 'South Africa' },
  //South Korea
  { code: 'es', value: 'spain', name: 'Spain', nativeName: 'España' },
  { code: 'lk', value: 'srilanka', name: 'Sri Lanka', nativeName: 'ශ්‍රී ලංකා' },
  { code: 'sd', value: 'sudan', name: 'Sudan', nativeName: 'السودان' },
  { code: 'sr', value: 'suriname', name: 'Suriname', nativeName: 'Suriname' },
  { code: 'se', value: 'sweden', name: 'Sweden', nativeName: 'Sverige' },
  { code: 'ch', value: 'switzerland', name: 'Switzerland', nativeName: 'Schweiz / Suisse / Svizzera' },
  { code: 'sy', value: 'syria', name: 'Syria', nativeName: 'سوريا' },
  { code: 'tw', value: 'taiwan', name: 'Taiwan', nativeName: '台灣' },
  { code: 'tj', value: 'tajikistan', name: 'Tajikistan', nativeName: 'Тоҷикистон' },
  { code: 'tz', value: 'tanzania', name: 'Tanzania', nativeName: 'Tanzania' },
  { code: 'th', value: 'thailand', name: 'Thailand', nativeName: 'ประเทศไทย' },
  { code: 'tl', value: 'timorleste', name: 'Timor-Leste', nativeName: 'Timor-Leste' },
  { code: 'tg', value: 'togo', name: 'Togo', nativeName: 'Togo' },
  { code: 'tt', value: 'trinidadandtobago', name: 'Trinidad and Tobago', nativeName: 'Trinidad and Tobago' },
  { code: 'tn', value: 'tunisia', name: 'Tunisia', nativeName: 'تونس' },
  { code: 'tr', value: 'turkey', name: 'Turkey', nativeName: 'Türkiye' },
  { code: 'tm', value: 'turkmenistan', name: 'Turkmenistan', nativeName: 'Türkmenistan' },
  { code: 'ug', value: 'uganda', name: 'Uganda', nativeName: 'Uganda' },
  { code: 'ua', value: 'ukraine', name: 'Ukraine', nativeName: 'Україна' },
  { code: 'ae', value: 'unitedarabemirates', name: 'United Arab Emirates', nativeName: 'الإمارات العربية المتحدة' },
  { code: 'gb', value: 'unitedkingdom', name: 'United Kingdom', nativeName: 'United Kingdom' },
  { code: 'us', value: 'unitedstates', name: 'United States of America', nativeName: 'United States of America' },
  { code: 'um', value: 'usminoroutlyingislands', name: 'United States Minor Outlying Islands', nativeName: 'United States Minor Outlying Islands' },
  { code: 'uy', value: 'uruguay', name: 'Uruguay', nativeName: 'Uruguay' },
  { code: 'uz', value: 'uzbekistan', name: 'Uzbekistan', nativeName: "Oʻzbekiston" },
  { code: 'vu', value: 'vanuatu', name: 'Vanuatu', nativeName: 'Vanuatu' },
  { code: 've', value: 'venezuela', name: 'Venezuela', nativeName: 'Venezuela' },
  { code: 'vn', value: 'vietnam', name: 'Vietnam', nativeName: 'Việt Nam' },
  { code: 'eh', value: 'westsahara', name: 'W. Sahara', nativeName: 'الصحراء الغربية' },
  { code: 'ye', value: 'yemen', name: 'Yemen', nativeName: 'اليمن' },
  { code: 'zm', value: 'zambia', name: 'Zambia', nativeName: 'Zambia' },
  { code: 'zw', value: 'zimbabwe', name: 'Zimbabwe', nativeName: 'Zimbabwe' }
];

const trimTo6 = (text: any) => {
  if (!text) return '';
  return text.length > 8 ? text.substring(0, 8) + '...' : text;
};

export default function Home() {
  const [searchValue, setSearchValue] = useState("");
  const [visibleCount, setVisibleCount] = useState(32);

  // useEffect(() => {
  //   if (typeof document !== "undefined") {
  //     if (getCookie("token") != null) {
  //       router.push("/home");
  //     }
  //   }
  // }, []);

  const clickHandler = (value: any) => {
    setSearchValue(value);
    setVisibleCount(30);
  };

  const filteredCountries = countries.filter((country) =>
    country.name.toLowerCase().includes(searchValue.toLowerCase()) || country.nativeName.toLowerCase().includes(searchValue.toLowerCase()) 
  );

  const displayedCountries = searchValue
    ? filteredCountries
    : filteredCountries.slice(0, visibleCount);

  return (
    <>
      <Head>
        <title>Business-Communicator – we connect business people around the world</title>
        <meta name="description" content="A ready-to-use starter template for building fast and modern web applications." />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div className="pb-24">
        <section className="flex flex-col md:flex-row justify-between items-center p-6">
          <JoinComunicatorSection />
          <div className="md:w-2/3">
            <h2 className="mt-8 text-center">
              Business-Communicator – we connect business people around the world.
            </h2>
            <WorldMap />
          </div>
        </section>
        <section className="bg-[#C9C6C6] p-4 px-10 mb-9">
          <SearchBar click={clickHandler} />
        </section>
        <section className="flex flex-col space-y-6">
          <div className="flex flex-wrap gap-4 xl:justify-start justify-center p-6">
          {displayedCountries.map((country, index) => (
              <a
                href={`/${country.code}/home`}
                onClick={() => {
                  document.cookie = `country=${encodeURIComponent(country.name)}; path=/;`;
                  document.cookie = `language=${encodeURIComponent(country.code)}; path=/;`;
                }}
                className="group relative no-underline text-black flex flex-col items-center w-24 text-center leading-[18px] cursor-pointer"
              >
                <span className={`fi fi-${country.code} text-[50px]`}></span>
                <span className="pt-2" title={country.name}>
                  {trimTo6(country.name)}
                </span>
                <span className="text-sm text-gray-500" title={country.nativeName}>
                  {trimTo6(country.nativeName)}
                </span>
                <div className="absolute left-1/2 transform -translate-x-1/2 top-full mt-1 hidden group-hover:block bg-gray-800 text-white text-xs p-1 rounded whitespace-nowrap z-10">
                  {country.name} — {country.nativeName}
                </div>
              </a>
          ))}
          </div>
          {!searchValue && filteredCountries.length > visibleCount && (
            <div className="flex justify-center">
              <ShowButton label="View more" onClick={() => setVisibleCount(visibleCount + 220)} />
            </div>
          )}
        </section>
      </div>
    </>
  );
}