import Link from "next/link";
import React from "react";

interface SubmitButtonProps {
  label: string;
  onClick?: () => void;
  isLink?: boolean;
  href?: string;
}
const GrayButton: React.FC<SubmitButtonProps> = ({ label, onClick, isLink, href }) => {

  if (isLink == true)
    {
      return (
        <Link 
        href={href || ""}
        className="w-full block text-center no-underline py-3 cursor-pointer bg-[#CBC8C8] text-[#383838] border-0 text-[18px] font-[500] rounded-lg hover:bg-gray-300 transition duration-300"
        >{label}</Link>
      )
    }

  return (
    <button
      type="submit"
      onClick={onClick}
      className="w-full py-3 cursor-pointer bg-[#CBC8C8] text-[#383838] border-0 text-[18px] font-[500] rounded-lg hover:bg-gray-300 transition duration-300"
    >
      {label}
    </button>
  );
};

export default GrayButton;
