import Link from "next/link";
import React from "react";

interface SubmitButtonProps {
  label: string;
  onClick?: () => void;
  isLink?: boolean;
  href?: string;
}

const BlueButton: React.FC<SubmitButtonProps> = ({ label, onClick, isLink, href }) => {

  if (isLink == true)
  {
    return (
      <Link 
      href={href || ""}
      className="w-full block text-center py-3 cursor-pointer bg-[#4988CE] no-underline text-white text-[18px] font-[500] border-0 rounded-lg hover:bg-blue-600 transition duration-300"
      >{label}</Link>
    )
  }

  return (
    <button
      type="submit"
      onClick={onClick}
      className="w-full py-3 cursor-pointer bg-[#4988CE] text-white text-[18px] font-[500] border-0 rounded-lg hover:bg-blue-600 transition duration-300"
    >
      {label}
    </button>
  );
};

export default BlueButton;
