import React, { useState } from "react";

interface SearchBarProps {
  click: (val: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({click}) => {

  const [country, setCountry] = useState<string>("");

  const onClickHandler = (e: any) => {
    e.preventDefault();
    click(country);
  }

  return (
    <div className="flex items-center w-full p-1">
      <input
        type="text"
        value={country}
        onChange={e => setCountry(e.target.value)}
        placeholder="Search country"
        className="flex-grow bg-white outline-none border-0 rounded-l-[8px] p-3 text-[#726C6C] text-[18px] font-[400] placeholder-[#726C6C]"
      />
      <button onClick={onClickHandler} className="bg-[#4988CE] text-white px-4 py-3 font-[400] text-[18px] rounded-r-[8px] border-0 hover:bg-blue-600 transition duration-300 cursor-pointer">
        Search
      </button>
    </div>
  );
};

export default SearchBar;
