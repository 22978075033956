import Arrow from "@/assets/Arrow";
import React from "react";

interface SubmitButtonProps {
  label: string;
  onClick?: () => void;
}
const ShowButton: React.FC<SubmitButtonProps> = ({ label, onClick }) => {
  return (
    <button
        onClick={onClick}
        className="flex justify-center items-center p-3 cursor-pointer bg-[#09080899] text-white border-0 text-[18px] font-[400] rounded-[8px] hover:bg-gray-500 transition duration-300"
    >
        <span className='pr-2'>{label}</span> <div className='flex'><Arrow className='stroke-white'/></div>
    </button>
  );
};

export default ShowButton;