import BlueButton from "./BlueButton"
import GrayButton from "./GrayButton"

const JoinComunicatorSection: React.FC = () => {

    return (
    <div className="w-full md:w-1/3">
       
        <h1 className="text-center text-[28px] leading-10">Join <br/> Business-Comunicator <br/> today!</h1>
    
        <BlueButton label="Sign up" isLink={true} href="/register"/>
        <p className="text-center">Or</p>
        <GrayButton label="Login" isLink={true} href="/login"/>
    </div>
    )
}

export default JoinComunicatorSection