import { countries } from "@/locales";
import router from "next/router";
import React, { useState, useEffect } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
} from "react-simple-maps";

const geoUrl = "https://cdn.jsdelivr.net/npm/world-atlas@2/countries-110m.json";

const WorldMap: React.FC = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Pierwsze sprawdzenie po załadowaniu
    checkMobile();
    // Aktualizacja przy zmianie rozmiaru okna
    window.addEventListener("resize", checkMobile);
    
    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, []);

  const handleClick = (geo: any) => {
    const countryName = geo.properties.name ?? "kraj";
    document.cookie = `country=${encodeURIComponent(countryName)}; path=/;`;
    const matchedCountry = countries.find(
      (country: any) => country.name === countryName
    );
    if (matchedCountry) {
      document.cookie = `language=${encodeURIComponent(matchedCountry.code)}; path=/;`;
    }

    router.push("/home");
  };

  return (
    <div className="min-h-[350px] w-full">
      <ComposableMap>
        <Geographies geography={geoUrl}>
          {({ geographies }: { geographies: any }) =>
            geographies.map((geo: any) => (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                onClick={!isMobile ? () => handleClick(geo) : undefined}
                style={{
                  default: { fill: "#D6D6DA", outline: "none" },
                  hover: { fill: "#F53", outline: "none" },
                  pressed: { fill: "#E42", outline: "none" },
                }}
              />
            ))
          }
        </Geographies>
      </ComposableMap>
    </div>
  );
};

export default WorldMap;
